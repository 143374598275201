import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DashboardService from "../services/dashboard.service";
import Loader from "../commonComponents/Loader";
import DateFromatter from "./DateFromatter";

const SubscriptionLogsModal = ({
  showLogs,
  setShowLogs,
  month,
  year,
  branch,
  filterLogs,
}) => {
  const [logData, setLogData] = useState({
    terminatedUsers: [],
    registeredUsers: [],
    packageReturnUsers: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (showLogs) {
      const fetchLogs = async () => {
        try {
          setLoading(true);
          const response = await DashboardService.getMonthSubLogs(
            month,
            year,
            branch
          );
          // Assuming the response contains an array of logs
          const logs = response.data?.data || null;

          if (logs) {
            // Separate logs based on logType
            const terminatedUsers = logs.filter(
              (log) => log.logType === "terminate"
            );
            const packageReturnUsers = logs.filter(
              (log) => log.logType === "packageReturn"
            );
            const registeredUsers = logs.filter(
              (log) => log.logType === "subscription"
            );

            setLogData({
              terminatedUsers,
              packageReturnUsers,
              registeredUsers,
            });
          }
        } catch (error) {
          console.error("Error fetching logs:", error);
          alert("Error fetching logs.");
        } finally {
          setLoading(false);
        }
      };

      fetchLogs();
    }
  }, [showLogs, month, year, branch]);

  const renderLogSection = (title, logs) => {
    let display = <p>No records found.</p>;
    if (logs.length !== 0) {
      display = (
        <ul>
          {logs.map((log, index) => {
            const fullName =
              log.usersubscribe?.user?.full_name || "Unknown User";

            return (
              <li key={index}>
                <strong>{fullName}</strong> -{" "}
                {DateFromatter.DateFormatterOne(log.newValue)}
              </li>
            );
          })}
        </ul>
      );
    }
    return (
      <>
        <h5>{title}</h5>
        {display}
      </>
    );
  };

  return (
    <Modal show={showLogs} onHide={() => setShowLogs(false)}>
      <Modal.Header>
        <Modal.Title>Logs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader />
        ) : (
          <>
            <>
              {(filterLogs == "" || filterLogs == "subscribers") &&
                renderLogSection("Subscribers", logData.registeredUsers)}
              {(filterLogs == "" || filterLogs == "terminated") &&
                renderLogSection("Terminated users", logData.terminatedUsers)}
              {(filterLogs == "" || filterLogs == "package_return") &&
                renderLogSection(
                  "Package return users",
                  logData.packageReturnUsers
                )}
            </>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <button style={{ width: "50%" }} onClick={() => setShowLogs(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionLogsModal;
