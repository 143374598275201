import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
} from "recharts";
import DashboardService from "../services/dashboard.service";
import EventBus from "../common/EventBus";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col } from "react-bootstrap";
import Loader from "../commonComponents/Loader";
import { CSVLink } from "react-csv";
import SubscriptionLogsModal from "../common/SubscriptionLogsModal";

const AnalyticsSubscriptions = () => {
  const monthsNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const lastYearDate = new Date(
    new Date().getFullYear() - 1,
    new Date().getMonth(),
    new Date().getDate()
  );
  const [fromDate, setFromDate] = useState(lastYearDate);
  const [toDate, setToDate] = useState(new Date());
  const [monthlySubscriptionsData, setMonthlySubscriptionsData] = useState([]);
  const [monthlySubscriptionsData2, setMonthlySubscriptionsData2] = useState(
    []
  );
  const [weeklySubscriptionsData, setWeeklySubscriptionsData] = useState([]);
  const [
    monthlyAccumulatedSubscriptionsData,
    setMonthlyAccumulatedSubscriptionsData,
  ] = useState([]);
  const [
    monthlyAccumulatedSubscriptionsData2,
    setMonthlyAccumulatedSubscriptionsData2,
  ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingWeekly, setLoadingWeekly] = useState(false);
  const [acumulatedLoading, setAcumulatedLoading] = useState(false);
  const sub_header = [
    { label: "Year", key: "year" },
    { label: "Month", key: "month" },
    { label: "Subscriptions", key: "Subscriptions" },
    { label: "New Subscriptions", key: "newSubscribers" },
    { label: "Terminated", key: "terminated" },
  ];
  const newly_sub_header = [
    { label: "Year", key: "year" },
    { label: "Month", key: "month" },
    { label: "Subscriptions", key: "Subscriptions" },
    { label: "Registered", key: "Registered" },
  ];
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [filterLogs, setFilterLogs] = useState("");
  const [showLogs, setShowLogs] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("user")) || null;

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      await triggerSearch(lastYearDate, new Date());
    }

    onReady();
  }, []);

  const triggerSearch = async (fromDateT, toDateT) => {
    if (fromDateT) {
      await getMonthlySubscriptions(fromDateT, toDateT);
      await getWeeklySubscripitons(fromDateT, toDateT);
      await getAccumulatedSubscriptions(fromDateT, toDateT);
    }
  };

  const getAccumulatedSubscriptions = async (fromDate, toDate) => {
    setAcumulatedLoading(true);

    DashboardService.getAccumulatedSubscriptions(
      fromDate.getMonth() + 1,
      fromDate.getFullYear(),
      toDate.getMonth() + 1,
      toDate.getFullYear()
    ).then(
      (response) => {
        let subs = response.data.data.anlaytics;
        let data = [];
        let data2 = [];
        subs &&
          subs.map((s, i) => {
            data.push({
              name: monthsNames[s._id.month - 1] + "\n" + s._id.year,
              Subscriptions: s.count,
              new: s.newSubscribers,
              terminated: s.terminated,
            });

            data2.push({
              month: monthsNames[s._id.month - 1],
              year: s._id.year,
              Subscriptions: s.count,
              newSubscribers: s.newSubscribers,
              terminated: s.terminated,
              monthNumber: s._id.month,
            });
          });

        setMonthlyAccumulatedSubscriptionsData(data);
        setMonthlyAccumulatedSubscriptionsData2(data2);
        setAcumulatedLoading(false);
        return data;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
        setAcumulatedLoading(false);
        return _content;
      }
    );
  };

  const getMonthlySubscriptions = async (fromDate, toDate) => {
    setLoading(true);
    DashboardService.getNewlySubscriptions(fromDate, toDate, "").then(
      (response) => {
        let subs = response.data.data.subs;
        let registered = response.data.data.registered;
        let data = [];
        let data2 = [];
        [...subs, ...registered].map((obj, index) => {
          const monthName = subs[index]
            ? monthsNames[subs[index]._id.month - 1]
            : registered[index]
            ? monthsNames[registered[index]._id.month - 1]
            : null;
          const year = subs[index]
            ? subs[index]._id.year
            : registered[index]
            ? registered[index]._id.year
            : null;
          const name = monthName + "\n" + year;
          if (monthName) {
            data.push({
              name,
              Subscriptions: subs[index]?.count ?? 0,
              Registered: registered[index]?.count ?? 0,
            });
            data2.push({
              month: monthName,
              year: year,
              Registered: registered[index]?.count ?? 0,
              Subscriptions: subs[index]?.count ?? 0,
              monthNumber: registered[index]?._id?.month,
            });
          }
        });

        setMonthlySubscriptionsData(data);
        setMonthlySubscriptionsData2(data2);
        setLoading(false);
        return data;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
        return _content;
      }
    );
  };

  const resetSearch = async () => {
    setFromDate(lastYearDate);
    setToDate(new Date());
    await triggerSearch(lastYearDate, new Date());
  };

  const getWeeklySubscripitons = async (fromDate, toDate) => {
    setLoadingWeekly(true);
    await DashboardService.getNewlySubscriptions(fromDate, toDate, true).then(
      async (response) => {
        let subs = response.data.data.subs;
        const getWeeksArray = (month, year) => {
          let weeks = [];
          let weeksLabel = [];
          subs.map((s, i) => {
            if (s._id.month === month && s._id.year === year) {
              weeksLabel.push(s._id.week);
              weeks.push(s.count);
            }
          });
          return { weeks, weeksLabel };
        };
        let data = [];
        let months = [];
        await subs.map((s, i) => {
          if (
            (months.length > 0 &&
              months[months.length - 1].month !== s._id.month) ||
            months.length === 0
          )
            months.push({ month: s._id.month, year: s._id.year });
        });

        months.map((m, i) => {
          let { weeks, weeksLabel } = getWeeksArray(m.month, m.year);
          data.push({
            name: monthsNames[m.month - 1] + "\n" + m.year,
            Week1: weeks[0] || 0,
            Week2: weeks[1] || 0,
            Week3: weeks[2] || 0,
            Week4: weeks[3] || 0,
            Week5: weeks[4] || 0,
            Week6: weeks[5] || 0,
            WeekLabel1: weeksLabel[0] || 0,
            WeekLabel2: weeksLabel[1] || 0,
            WeekLabel3: weeksLabel[2] || 0,
            WeekLabel4: weeksLabel[3] || 0,
            WeekLabel5: weeksLabel[4] || 0,
            WeekLabel6: weeksLabel[5] || 0,
          });
        });

        setWeeklySubscriptionsData(data);
        setLoadingWeekly(false);
        return data;
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
        setLoadingWeekly(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="weekly-tooltip">
          <div className="label">{`${label}`}</div>
          <div>
            {payload.map((pld) => {
              let wLabel = pld.payload.WeekLabel1;
              if (pld.dataKey == "Week2") {
                wLabel = pld.payload.WeekLabel2;
              } else if (pld.dataKey == "Week3") {
                wLabel = pld.payload.WeekLabel3;
              } else if (pld.dataKey == "Week4") {
                wLabel = pld.payload.WeekLabel4;
              } else if (pld.dataKey == "Week5") {
                wLabel = pld.payload.WeekLabel5;
              } else if (pld.dataKey == "Week6") {
                wLabel = pld.payload.WeekLabel6;
              }
              if (wLabel !== 0) {
                return (
                  <div>
                    <div style={{ color: pld.fill }}>
                      Week {wLabel}: {pld.value}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }

    return null;
  };

  const viewLogs = (month, year, filter) => {
    setMonth(month);
    setYear(year);
    setFilterLogs(filter);
    setShowLogs(true);
  };
  return (
    <div>
      <h3>Analytics Subscriptions</h3>
      <div className="filterContainer">
        <div className="d-flex flex-row">
          <Col lg={3} className="p-0">
            <label
              htmlFor="start"
              className="filterLabelInput maintenanceLabels"
            >
              From
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="MM-yyyy"
              className="form-control filterInput2"
              name="start"
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              showMonthYearPicker
            />
          </Col>
          <Col lg={3} className="p-0">
            <label htmlFor="to" className="filterLabelInput maintenanceLabels">
              To
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="MM-yyyy"
              className="form-control filterInput2"
              name="end"
              selected={toDate}
              onChange={(date) => setToDate(date)}
              showMonthYearPicker
            />
          </Col>
          <Col lg={3} className="p-0">
            <div style={{ marginTop: 35 }} border="0">
              <div className="d-flex flex-row align-items-start">
                <button
                  onClick={(e) => triggerSearch(fromDate, toDate)}
                  className="btn btn-primary search-button"
                >
                  Go
                </button>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary reset-button"
                >
                  Reset
                </button>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <br />
      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "first baseline",
        }}
      >
        <Col lg={5.5}>
          <h5 style={{ paddingLeft: 50 }}>
            Newly Monthly Subscriptions - Registered
          </h5>
          {loading ? (
            <Loader />
          ) : (
            <LineChart
              width={450}
              height={300}
              data={monthlySubscriptionsData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid stroke="#eee" />
              <Line type="monotone" dataKey="Subscriptions" stroke="#8884d8" />
              <Line type="monotone" dataKey="Registered" stroke="green" />
              <Tooltip />
            </LineChart>
          )}
        </Col>
        <Col lg={5.5}>
          <h5 style={{ paddingLeft: 50 }}>Newly Weekly Subscriptions</h5>
          {loadingWeekly ? (
            <Loader />
          ) : (
            <BarChart
              width={480}
              height={300}
              data={weeklySubscriptionsData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" stroke="#595959" />
              <YAxis stroke="#595959" />
              <Tooltip content={<CustomTooltip />} />

              <Bar dataKey="Week1" fill="#368b85" />
              <Bar dataKey="Week2" fill="purple" />
              <Bar dataKey="Week3" fill="#ff8c00" />
              <Bar dataKey="Week4" fill="green" />
              <Bar dataKey="Week5" fill="#3db3e4" />
              <Bar dataKey="Week6" fill="#0db66e" />
            </BarChart>
          )}
        </Col>
      </Row>
      {!loading &&
      monthlySubscriptionsData2 &&
      monthlySubscriptionsData2.length != 0 ? (
        <div style={{ paddingLeft: 50, marginTop: 50 }}>
          <h3> Newly Subscription - Registered List </h3>
          <div className="floatRight ">
            <CSVLink
              data={monthlySubscriptionsData2}
              headers={newly_sub_header}
              filename={
                fromDate.getMonth() +
                1 +
                "-" +
                fromDate.getFullYear() +
                "" +
                " to " +
                (toDate.getMonth() + 1) +
                "-" +
                toDate.getFullYear() +
                "-" +
                "newlySubscriptions.csv"
              }
            >
              <i alt="View" title="View" className="fa fa-download"></i>
            </CSVLink>
          </div>
          <table className="styled-table">
            <thead>
              <tr>
                <th>Year</th>
                <th>Month</th>
                <th>Total Subscribers</th>
                <th>Total Registered</th>
              </tr>
            </thead>
            <tbody>
              {monthlySubscriptionsData2 &&
              monthlySubscriptionsData2.length != 0
                ? monthlySubscriptionsData2.map((m) => {
                    return (
                      <tr>
                        <td>{m.year}</td>
                        <td>{m.month}</td>
                        <td>
                          {m.Subscriptions}{" "}
                          {(m.year > 2024 ||
                            (m.monthNumber == 12 && m.year == 2024)) && (
                            <a
                              href="javascript:void(0)"
                              className="greenLink mx-0"
                              onClick={() =>
                                viewLogs(m.monthNumber, m.year, "")
                              }
                            >
                              Logs
                            </a>
                          )}
                        </td>
                        <td>{m.Registered}</td>
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </table>
        </div>
      ) : (
        <div> No record found.</div>
      )}

      <Row
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "first baseline",
        }}
      >
        <Col lg={10}>
          <h5 style={{ paddingLeft: 50 }}>Accumulated Monthly Subscriptions</h5>
          {acumulatedLoading ? (
            <Loader />
          ) : (
            <LineChart
              width={600}
              height={300}
              data={monthlyAccumulatedSubscriptionsData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <YAxis />
              <CartesianGrid stroke="#eee" />
              <XAxis dataKey="name" />
              <Line type="monotone" dataKey="Subscriptions" stroke="#8884d8" />
              <Line type="monotone" dataKey="terminated" stroke="red" />
              <Line type="monotone" dataKey="new" stroke="green" />
              <Tooltip />
            </LineChart>
          )}
        </Col>
      </Row>
      {!acumulatedLoading &&
      monthlyAccumulatedSubscriptionsData2 &&
      monthlyAccumulatedSubscriptionsData2.length != 0 ? (
        <div style={{ paddingLeft: 50, marginTop: 50 }}>
          <h3> Accumulated Monthly Subscriptions</h3>
          <div className="floatRight ">
            <CSVLink
              data={monthlyAccumulatedSubscriptionsData2}
              headers={sub_header}
              filename={
                fromDate.getMonth() +
                1 +
                "-" +
                fromDate.getFullYear() +
                "" +
                " to " +
                (toDate.getMonth() + 1) +
                "-" +
                toDate.getFullYear() +
                "-" +
                "accummulated-subscriptions.csv"
              }
            >
              <i alt="View" title="View" className="fa fa-download"></i>
            </CSVLink>
          </div>
          <table className="styled-table">
            <thead>
              <tr>
                <th>Year</th>
                <th>Month</th>
                <th>Subscriptions</th>
                <th>Newly Subscriptions</th>
                <th>Terminated</th>
              </tr>
            </thead>
            <tbody>
              {monthlyAccumulatedSubscriptionsData2 &&
              monthlyAccumulatedSubscriptionsData2.length != 0
                ? monthlyAccumulatedSubscriptionsData2.map((m) => {
                    return (
                      <tr>
                        <td>{m.year}</td>
                        <td>{m.month}</td>
                        <td>{m.Subscriptions}</td>
                        <td>{m.newSubscribers}</td>
                        <td>
                          {m.terminated}{" "}
                          {(m.year > 2024 ||
                            (m.monthNumber == 12 && m.year == 2024)) && (
                            <a
                              href="javascript:void(0)"
                              className="greenLink mx-0"
                              onClick={() =>
                                viewLogs(m.monthNumber, m.year, "terminated")
                              }
                            >
                              Logs
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : "No record found"}
            </tbody>
          </table>
        </div>
      ) : (
        <div> No records found.</div>
      )}
      <SubscriptionLogsModal
        showLogs={showLogs}
        setShowLogs={setShowLogs}
        month={month}
        year={year}
        branch={currentUser.branch}
        filterLogs={filterLogs}
      />
    </div>
  );
};

export default AnalyticsSubscriptions;
